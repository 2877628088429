@import '../../functions';
@import '../../extends';

.wizard_container{
  background: #F6F8FA;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  width: px-to-em(800, 16);
  height: px-to-em(614, 16);
  border-radius: 2em;
  border: 4px solid color(Black, Transparent, 50);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  z-index: 901;
  --bottom-height: 104px;
  --image-size: 115px;
  --mode-button-size: 450px;
  .wizard_head{
    .wizard_details_container{
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, -37%);
    }
    .wizard_image_container{
      width: 80px;
      height: 80px;
      border: 6px solid transparent;
      border-radius: 50%;
      margin: 0 auto;
      &.classic{
        background: #E1E9F9;
        border-color: color(Primary, Solid, 500);
        filter: drop-shadow(-1px 4px 16px rgba(35, 71, 133, 0.32));
      }
      &.private{
        background: #D9EDDA;
        border-color: color(Green, Solid, 500);
        filter: drop-shadow(-1px 4px 16px rgba(17, 146, 24, 0.32));;
      }
      .wizard_image{
        width: 100%;
        height: 100%;
      }
    }
    .wizard_title_container{
      width: 100%;
      margin-top: px-to-em(8, 16);
      .wizard_title{
        text-align: center;
        font-size: px-to-em(24, 16);
        color: color(Black, Solid, 400);
        font-weight: 700;
      }
    }
    .wizard_close_button_container{
      position: absolute;
      right: 24px;
      top: 24px;
    }
    @media (max-width: 1023px) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      .wizard_details_container{
        transform: unset;
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        left: 0;
        .wizard_title_container{
          width: auto;
          .wizard_title{
            display: inline-block;
            width: auto;
            font-size: px-to-em(32, 16);
            padding-left: 1em;
            margin: 0;
          }
        }
        .wizard_image_container{
          margin: 0;
        }
      }
      .wizard_close_button_container{
        position: relative;
        top: 0;
        left: 0;
        transform: unset;
      }
      @media (orientation: landscape) {
        padding: px-to-em(8, 16) 1em;
        .wizard_details_container{
          justify-content: center;
        }
        .wizard_image_container{
          width: var(--image-size);
          height: var(--image-size);
        }
      }
      @media (orientation: portrait) {
        padding: 12px getPercent(12, 360);
        .wizard_details_container{
          justify-content: flex-start;
        }
        .wizard_image_container{
          width: var(--image-size);
          height: var(--image-size);
        }
        .wizard_close_button_container{
          button{
            width: calc(#{var(--image-size)} / 2);
            height: calc(#{var(--image-size)} / 2);
            min-width: 32px;
            min-height: 32px;
            img {
              width: 100%;
              max-width: 22px;
            }
          }
        }
      }
    }
    @media (max-width: 767px) {
      .wizard_details_container{
        .wizard_title_container{
          .wizard_title{
            font-size: 24px;
          }
        }
      }
      .wizard_close_button_container{
        button{
          img {
            width: 100%;
            max-width: 15px;
          }
        }
      }
      @media (orientation: portrait) {
        
      }
    }
  }
  .upper_part{
    background: white;
    width: 100%;
    height: calc(100% - var(--bottom-height));
    border-radius: 2em;
    .dynamic_part{
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding-top: 70px;
      .dynamic_title{
        font-size: px-to-em(18, 16);
        color: color(Black, Solid, 400);
        font-weight: 300;
        width: 100%;
        text-align: center;
        padding-top: px-to-em(12, 16);
      }
      .dynamic_body{
        height: calc(100% - px-to-em(35.5, 16));
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .buttons_container{
          margin: 0 auto;
          &.mode_container{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            .inner_container{
              display: flex;
              justify-content: center;
              align-items: center;
              flex-wrap: wrap;
              width: 100%;
            }
          }
          &.players_container{
            display: flex;
            justify-content: center;
            align-items: center;
            width: calc(470px + (6 * 0.5em));
            flex-wrap: wrap;
            .inner_container{
              display: flex;
              justify-content: center;
              align-items: center;
              flex-wrap: wrap;
              width: 100%;
            }
            .button_wrapper{
              // width: calc(100% / 3);
              // margin: 0.5em;
              box-sizing: border-box;
              position: relative;
              // &::after{
              //   content: "";
              //   display: block;
              //   padding-top: getPercent(122, 146);
              // }
              //new styles
              width: getPercent(146, 632);
              max-width: 146px;
              margin-right: 1em;
              &:last-child{
                margin-right: 0;
              }
            }
            //new styles
            width: 81%;
          }
          &.rules_container{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 656px;
            flex-wrap: wrap;
            .inner_container{
              display: flex;
              justify-content: center;
              align-items: center;
              flex-wrap: wrap;
              width: 100%;
            }
            .button_wrapper{
              width: calc(100% / 3);
              padding: 8px;
              // padding: 0 1em 1em 1em;
              // &:nth-child(-n + 3) {
              //   padding-top: 1em;
              // }
            }
            &.narrow{
              width: 480px;
              .button_wrapper{
                width: 50%;
                padding: 0 1em 1em 1em;
                &:nth-child(-n + 2) {
                  padding-top: 1em;
                }  
              }
            }
          }
          &.friends_container{
            width: 624px;
            height: 100%;
            overflow-y: auto;
            .inner_container{
              display: flex;
              justify-content: center;
              align-items: center;
              flex-wrap: wrap;
              width: 100%;
            }
            .player_select_ul{
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              flex-wrap: wrap;
              list-style-type: none;
              padding: 0;
              .player_select_li{
                width: calc(100% / 6);
                margin-top: 15px;
                .select_friend_container{
                  cursor: pointer;
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: flex-start;
                  flex-wrap: wrap;
                  .username{
                    display: inline-block;
                    width: 100%;
                    text-align: center;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                  }
                }
              }
            }
          }
          &.game_name{
            width: 272px;
            .title{
              font-size: 14px;
              color: color(Black, Solid, 400);
              width: 100%;
              text-align: center;
            }
            .form_group{
              margin-top: 1em;
            }
          }
          &.calendar_container{
            width: 641px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .calendar{
              border: 0;
            }
            .set_time_container{
              display: flex;
              justify-content: flex-end;
              align-items: center;
              .set_time{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-right: 0.5em;
                .time_container{
                  width: px-to-em(96, 16);
                  height: px-to-em(80, 16);
                  border-radius: px-to-em(12, 16);
                  box-shadow: 0px 2px 4px rgba(51, 51, 51, 0.08);
                  border: 1px solid color(Black, Transparent, 100);  
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  flex-direction: column;
                  box-sizing: border-box;
                  padding: px-to-em(8, 16);
                  margin: 1em 0;
                  .time_title{
                    font-size: px-to-em(12, 16);
                    color: color(Black, Solid, 300);
                  }
                  .time_value{
                    font-size: px-to-em(24, 16);
                    color: color(Black, Solid, 400);
                    font-weight: bold;
                    display: inline-block;
                    margin-top: px-to-em(5, 16);
                  }
                }
                &:last-child{
                  margin-right: 0;
                }
              }
            }
            .responsive_date_container{
              display: none;
            }
            @media (max-width: 992px) {
              display: flex;
              justify-content: center;
              align-items: flex-start;
              width: 100%;
              height: 100%;
              overflow-y: auto;
              .calendar{
                display: none;
              }
              .set_time_container{
                display: none;
              }
              .responsive_date_container{
                display: flex;
                width: 100%;
                height: 100%;
                justify-content: center;
                align-items: center;
                .responsive_date{
                  background: linear-gradient(247.2deg, #FFFFFF 5.25%, #E4E6F2 77.64%);
                  border: 4px solid #F1F4F8;
                  border-radius: px-to-em(24, 16);
                  box-shadow: 0px 4px 8px rgba(63, 81, 113, 0.04);
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 45%;
                  min-width: 372px;
                  position: relative;
                  .responsive_date_side{
                    width: 50%;
                    position: relative;
                    .content{
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                      height: 100%;
                      .title{
                        width: 100%;
                        font-size: px-to-em(18, 16);
                        font-weight: 300;
                        color: color(Black, Solid, 400);
                        text-align: center;
                        margin-bottom: px-to-em(12, 18);
                      }
                      .details{
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .time_part{
                          font-size: px-to-em(24, 16);
                          font-weight: 700;
                          color: color(Black, Solid, 400);
                          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
                        }
                        .icon_container{
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          width: px-to-em(40, 16);
                          height: px-to-em(40, 16);
                          border-radius: 50%;
                          background: color(Black, Transparent, 25);
                          margin-left: px-to-em(12, 16);
                        }
                      }
                    }
                    &::after{
                      content: "";
                      display: block;
                      padding-top: getPercent(150, 186);
                    }
                  }
                  .time_input{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: 1;
                    opacity: 0;
                  }
                }
              }
            }
            @media (max-width: 767px) {
              @media (orientation: portrait) {
                .responsive_date_container{
                  .responsive_date{
                    flex-direction: column;
                    width: 177px;
                    min-width: unset;
                    height: 300px;
                    .responsive_date_side{
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
        .button_container{
          box-sizing: border-box;
          position: relative;
          position: relative;
          cursor: pointer;
          .container{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: all 500ms;
            border: 4px solid transparent;
            border-radius: px-to-em(24, 16);
            overflow: hidden;
            &.standart_background{
              background: gradient(Light, 500);
              border-color: color(Primary, Solid, 25);
              opacity: 1;
              z-index: 1;
              box-shadow: 0px 4px 8px rgba(63, 81, 113, 0.08);
            }
            &.hover_background{
              background: gradient(Light, 600);
              border-color: color(Primary, Solid, 25);
              opacity: 0;
              z-index: 2;
              box-shadow: 0px 4px 16px rgba(63, 81, 113, 0.16);
            }
            &.active_background{
              background: gradient(Primary, 500);
              border-color: color(Primary, Solid, 500);
              opacity: 0;
              z-index: 3;
              box-shadow: 0px 4px 8px rgba(63, 81, 113, 0.08);
            }
            &.disabled_background{
              background: gradient(Light, 500);
              border-color: color(Primary, Solid, 25);
              opacity: 0;
              z-index: 4;
            }
            &.content_container{
              opacity: 1!important;
              z-index: 5;
            }
          }
          &:hover{
            .container{
              &.hover_background{
                opacity: 1;
              }
            }
          }
          &.active{
            .container{
              &.active_background{
                opacity: 1;
              }
            }
          }
          &.disabled{
            opacity: 0.4;
            cursor: no-drop;
            .disabled_text_container{
              font-size: px-to-em(12, 16); //16px
              font-weight: bold;
              line-height: px-to-em(19, 12);
              color: color(White, Solid, 500);
              background: color(Black, Transparent, 100);
              padding: px-to-em(9, 12) px-to-em(15, 12);
              transform: skewX(-5deg);
              border-radius: px-to-em(8, 12);
              margin-top: px-to-em(10, 12);
              box-sizing: border-box;
              text-transform: uppercase;
              z-index: 1;
              span{
                transform: skewX(5deg);
              }
              @media (max-width: 1024px) {
                margin-top: 0.5em;
              }
            }
          }
          &.mode{
            // width: px-to-em(240, 16);
            width: getPercent(240, 800);
            // height: px-to-em(200, 16);
            // height: 100%;
            margin-right: getPercent(19, 800);
            min-width: 240px;
            // padding-top: getPercent(200, 240);
            .content_container{
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
              .image_container{
                height: 50%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .text_container{
                display: flex;
                justify-content: center;
                align-items: flex-start;
                width: 90%;
                h5{
                  font-size: px-to-em(24, 16);
                  color: color(Black, Solid, 400);
                  font-weight: 700;
                  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
                  // text-transform: capitalize;
                  transition: all 500ms;
                  text-align: center;
                }
              }
            }
            &.active{
              .content_container{
                .text_container{
                  h5{
                    color: color(White, Solid);
                  }
                }
              }
            }
            &:last-child{
              margin-right: 0;
            }
            &::after{
              content: "";
              display: block;
              padding-top: getPercent(200, 240);
            }
          }
          &.players_number{
            // width: px-to-em(146, 16);
            // height: px-to-em(122, 16);
            width: 100%;
            // height: 100%;
            position: relative;
            // top: 0;
            // left: 0;
            .content_container{
              display: flex;
              justify-content: center;
              align-items: center;
              h5{
                display: inline-block;
                font-size: px-to-em(32, 16);
                color: color(Black, Solid, 400);
                text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
                font-weight: bold;
              }
              //new styles
              flex-wrap: wrap;
              flex-direction: column;              
            }
            .any_rules {
              width: 50.7%;
            }
            &.active{
              .content_container{
                h5{
                  color: color(White, Solid);
                }
              }
            }
            &::after{
              content: "";
              display: block;
              padding-top: getPercent(122, 146);
            }
          }
          &.rules{
            // width: px-to-em(208, 16);
            // height: px-to-em(168, 16);
            width: 100%;
            // height: px-to-em(168, 16);
            position: relative;
            .content_container{
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
              .image_container{
                height: px-to-em(74, 16);
                width: 100%;
                padding: px-to-em(5, 16) 0;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                  width: auto;
                  display: block;
                }
              }
              .text_container{
                width: 100%;
                height: calc(100% - #{px-to-em(74, 16)});
                .rule_title{
                  width: 100%;
                  text-align: center;
                  color: color(Black, Solid, 400);
                  font-weight: 700;
                  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
                  font-size: px-to-em(18, 16);
                  transition: all 500ms;
                }
                .rule_description{
                  width: 100%;
                  text-align: center;
                  font-size: px-to-em(12, 16);
                  color: color(Black, Solid, 300);
                  font-weight: 400;
                  max-width: getPercent(165, 208);
                  margin: px-to-em(6, 12) auto 0;
                  transition: all 500ms;
                }
                @media(max-width: 1023px) {
                  @media (orientation: portrait) {
                    .rule_title{font-size: 20px;}
                    .rule_description{font-size: 16px;}
                  }
                }
                @media(max-width: 800px) {
                  @media (orientation: portrait) {
                    .rule_title{font-size: 18px;}
                    .rule_description{font-size: 14px;}
                  }
                }
                @media(max-width: 767px) {
                  @media (orientation: portrait) {
                    .rule_title{font-size: 14px;}
                    .rule_description{font-size: 12px; max-width: 100%; width: 100%;}
                  }
                }
              }
            }
            &.active{
              .content_container{
                .text_container{
                  .rule_title{
                    color: color(White, Solid);
                  }
                  .rule_description{
                    color: color(White, Transparent, 400);
                  }
                }
              }
            }
            &::after{
              content: "";
              display: block;
              padding-top: getPercent(168, 208);
            }
          }
          &.scheduled_button{
            .content_container{
              .text_container{
                width: 100%;
              }
            }
          }
          &.private_button{
            .content_container{
              .text_container{
                @media (max-width: 976px) {
                  // font-size: 20px!important;
                }
              }
            }
          }
        }
      }
    }
    @media (max-width: 1023px) {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      border-radius: 0;
      .dynamic_part{
        padding-top: 0;
        .dynamic_title{
          padding-top: 0;
        }
        .dynamic_body{
          
        }
      }
      @media (orientation: portrait) {
        .dynamic_part{
          height: calc(100% - #{var(--image-size)} - 24px);
          .dynamic_title{
            font-size: 24px;
          }
          .dynamic_body{
            height: calc(100% - 28px);
            .buttons_container{
              &.players_container{
                width: 100%;
                height: 100%;
                overflow-y: auto;
                .inner_container{
                  // width: 60%;
                  // justify-content: flex-start;
                  .button_wrapper{
                    width: calc((100% - 2em) / 2);
                    margin: 0.5em;
                  }
                }
                // height: 100%;
              }
              &.rules_container{
                width: 100%;
                height: 100%;
                overflow-y: auto;
                .inner_container{
                  justify-content: flex-start;
                  width: calc(65%);
                  .button_wrapper{
                    width: calc((100%) / 2);
                    .button_container{
                      .content_container{
                        .image_container{
                          img {
                            width: 30%;
                          }
                        }
                      }
                    }
                  }
                }
                &.narrow{
                  width: 100%;
                  height: 100%;
                } 
              }
              &.friends_container{
                width: 100%;
                height: 100%;
              }
            }
            // overflow-y: auto;
          }
        }
      }
      @media (orientation: landscape) {
        .dynamic_part{
          height: calc(100% - #{var(--image-size)} - 16px);
          .dynamic_title{
            font-size: 16px;
          }
          .dynamic_body{
            height: calc(100% - 19px);
            .buttons_container{
              &.mode_container{
                overflow-y: auto;
                width: 100%;
                height: 100%;
                .inner_container{
                  .button_container{
                    width: getPercent(170, 740);
                    min-width: 190px;
                  }
                }
              }
              &.players_container{
                width: 95%;
                height: 100%;
                overflow-y: auto;

                .inner_container{
                  .button_wrapper{
                    width: calc((100% - 80px) / 5);
                  }
                  .button_container{
                    width: 100%;
                    // min-width: 190px;
                  }
                }
              }
              &.rules_container{
                width: 95%;
                height: 100%;
                &.narrow{
                  width: 100%;
                  height: 100%;
                }
                .inner_container{
                  width: 100%;
                  height: 100%;
                  .button_wrapper{
                    width: 20%;
                    .button_container{
                      &::after{
                        padding-top: 100%;
                      }
                    }
                  }
                }
              }
              &.friends_container{
                width: 100%;
                height: 100%;
                .inner_container{
                  .player_select_ul{
                    .player_select_li{
                      width: calc(100% / 7);
                      .select_friend_container{
                        .username{
                          font-size: 14px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    @media (max-width: 992px) {
      @media (orientation: portrait) {
        .dynamic_part{
          .dynamic_body{
            .buttons_container{
              &.rules_container{
                .inner_container{
                  width: calc(70%);
                }
              }
            }
            // overflow-y: auto;
          }
        }
      }
      @media (orientation: landscape) {
        .dynamic_part{
          height: calc(100% - #{var(--image-size)} - 16px);
          .dynamic_title{
            font-size: 16px;
          }
          .dynamic_body{
            height: calc(100% - 19px);
            .buttons_container{
              &.mode_container{
                overflow-y: auto;
                width: 100%;
                height: 100%;
                .inner_container{
                  .button_container{
                    width: getPercent(170, 740);
                    min-width: 190px;
                    &.private_button{
                      .content_container{
                        .text_container{
                          font-size: 12px;
                        }
                      }
                    }
                  }
                }
              }
              &.players_container{
                width: 95%;
                height: 100%;
                overflow-y: auto;
                .inner_container{
                  .button_wrapper{
                    width: calc((100% - 80px) / 5);
                  }
                  .button_container{
                    width: 100%;
                    // min-width: 190px;
                  }
                }
              }
              &.rules_container{
                width: 100%;
                .inner_container{
                  .button_wrapper{
                    // height: 100%;
                    .button_container{
                      // height: 100%;
                      max-height: 150px;
                      .content_container{
                        .image_container{
                          padding: 0;
                          height: 35%;
                        }
                        .text_container{
                          height: 65%;
                          .rule_title{
                            font-size: 14px;
                          }
                          .rule_description{
                            font-size: 12px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    @media (max-width: 767px) {
      @media (orientation: portrait) {
        .dynamic_part{
          .dynamic_title{
            font-size: 16px;
          }
          .dynamic_body{
            .buttons_container{
              &.players_container{
                .inner_container{
                  width: 70%;
                }
              }
              &.rules_container{
                .inner_container{
                  width: calc(80%);
                }
              }
              &.friends_container{
                .inner_container{
                  .player_select_ul{
                    .player_select_li{
                      width: calc(100% / 5);
                      .select_friend_container{
                        .username{
                          font-size: 14px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      @media (orientation: landscape) {
        .dynamic_part{
          .dynamic_body{
            .buttons_container{
              &.mode_container{
                .inner_container{
                  .button_container{
                    width: getPercent(170, 740);
                    min-width: 170px;
                    .content_container{
                      .image_container{
                        img {
                          width: 30%;
                        }
                      }
                      .text_container{
                        h5{
                          font-size: 18px;
                        }
                      }
                    }
                    &:last-child{
                      &:not(.scheduled_button){
                        .content_container{
                          .image_container{
                            img {
                              width: 70%;
                            }
                          }  
                        }
                      }
                    }
                  }
                }
              }
              &.players_container{
                width: 100%;
                .inner_container{
                  .button_wrapper{
                    width: calc((100% - 80px) / 5);
                  }
                  .button_container{
                    width: 100%;
                    // min-width: 190px;
                  }
                }
              }
              &.rules_container{
                .inner_container{
                  .button_wrapper{
                    height: 100%;
                    .button_container{
                      height: 100%;
                    }
                  }
                }
              }
              &.friends_container{
                width: 100%;
                height: 100%;
                .inner_container{
                  .player_select_ul{
                    .player_select_li{
                      width: calc(100% / 6);
                      .select_friend_container{
                        .username{
                          font-size: 14px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    @media (max-width: 739px) {
      @media (orientation: landscape) {
        .dynamic_part{
          .dynamic_body{
            .buttons_container{
              &.players_container{
                width: 100%;
                .inner_container{
                  .button_wrapper{
                    width: calc((100% - 60px) / 5);
                    margin: 5px 6px;
                    .button_container{
                      &::after{
                        padding-top: 100%;
                      }
                    }
                  }
                  // .button_container{
                  //   width: 100%;
                  //   // min-width: 190px;
                  // }
                }
              }
              &.rules_container{
                .inner_container{
                  .button_wrapper{
                    padding: 4px 6px;
                    .button_container{
                      .content_container{
                        .image_container{
                          padding: 0;
                          height: 35%;
                          img {
                            max-width: 40px;
                          }
                        }
                        .text_container{
                          .rule_title{
                            font-size: 10px;
                          }
                          .rule_description{
                            font-size: 8px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    @media (max-width: 580px) {
      @media (orientation: portrait) {
        .dynamic_part{
          .dynamic_title{
            font-size: 16px;
          }
          .dynamic_body{
            // overflow-y: auto;
            .buttons_container{
              &.mode_container{
                width: 60%;
                // height: 100%;
                flex-wrap: wrap;
                min-width: 260px;
                overflow-y: auto;
                .mode{
                  min-width: unset;
                  margin-right: 0;
                  width: 80%;
                  margin-top: 24px;
                  &:first-child{
                    margin-top: 0;
                  }
                }
              }
              &.rules_container{
                .inner_container{
                  width: calc(90%);
                }
              }
              &.friends_container{
                .inner_container{
                  .player_select_ul{
                    .player_select_li{
                      width: calc(100% / 4);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    @media (max-width: 500px) {
      @media (orientation: portrait) {
        .dynamic_part{
          .dynamic_body{
            // overflow-y: auto;
            .buttons_container{
              &.players_container{
                .inner_container{
                  width: 80%;
                }
              }
              &.rules_container{
                .inner_container{
                  width: 100%;
                }
              }
              &.friends_container{
                .inner_container{
                  .player_select_ul{
                    .player_select_li{
                      width: calc(100% / 3);
                    }
                  }
                }
              }
              &.mode_container{
                .private_button{
                  .content_container{
                    .text_container{
                      font-size: 12px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    @media (max-width: 400px) {
      @media (orientation: portrait) {
        .dynamic_part{
          .dynamic_body{
            // overflow-y: auto;
            .buttons_container{
              &.players_container{
                .inner_container{
                  width: 90%;
                }
              }
              &.rules_container{
                .inner_container{
                  .button_wrapper{
                    .button_container{
                      &::after{
                        padding-top: getPercent(146, 150);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    @media (max-width: 359px) {
      @media (orientation: portrait) {
        .dynamic_part{
          .dynamic_body{
            // overflow-y: auto;
            .buttons_container{
              &.players_container{
                .inner_container{
                  width: 95%;
                }
              }
            }
          }
        }
      }
    }
  }
  .bottom_part{
    height: var(--bottom-height);
    width: 100%;
    padding: 0 px-to-em(32, 16);
    display: flex;
    box-sizing: border-box;
    justify-content: flex-start;
    align-items: center;
    .left_side{
      width: px-to-em(160, 16);
      // background: red;
      height: 30px;
    }
    .center{
      width: calc(100% - #{px-to-em(320, 16)});
      display: flex;
      justify-content: center;
      .setpper_container{
        display: flex;
        justify-content: center;
        align-items: center;
        .stepper_part{
          position: relative;
          height: px-to-em(84, 16);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          &.circles{
            width: px-to-em(40, 16);
            .stepper_title{
              position: absolute;
              top: 0;
              left: 50%;
              transform: translateX(-50%);
              width: px-to-em(75, 12);
              font-size: px-to-em(12, 16);
              text-align: center;
              color: color(Black, Solid, 400);
              font-weight: 400;
              transition: all 500ms;
              // text-transform: capitalize;
              white-space: nowrap;
              @media (max-width: 375px) {
                font-size: 10px!important;
              }
            }
            .stepper_circle{
              width: px-to-em(40, 16);
              height: px-to-em(40, 16);
              background: color(Primary, Solid, 100);
              border-radius: 50%;
              box-sizing: border-box;
              border: 4px solid transparent;
              transition: all 500ms;
              position: relative;
              &::after{
                content: "";
                position: absolute;
                background: linear-gradient(253.43deg, #A0BD00 0%, #8AA302 100%);
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 0;
                height: 0;
                transition: all 500ms;
                border-radius: 50%;
                z-index: 1;
              }
              .checkmark{
                position: absolute;
                z-index: 2;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                opacity: 0;
                transition: all 500ms;
                svg {
                  width: 16px;
                  path{
                    fill: white;
                  }
                }
              }
            }
            .setpper_value{
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
              width: px-to-em(75, 12);
              font-size: px-to-em(12, 16);
              text-align: center;
              color: color(Black, Solid, 400);
              font-weight: bold;
              // text-transform: capitalize;
              white-space: nowrap;
              @media (max-width: 375px) {
                font-size: 10px;
                span {
                  &:last-child{
                    display: none;
                  }  
                }
              }
            }
          }
          &.line{
            width: px-to-em(28, 16);
            height: px-to-em(8, 16);
            margin: 0 5px;
            .stepper_line{
              background: color(Primary, Solid, 50);
              position: relative;
              width: 100%;
              height: 100%;
              &::after{
                content: "";
                position: absolute;
                width: 0;
                height: 100%;
                transition: all 500ms;
                background: color(Secondary, Solid, 600);
              }  
            }
          } 
          &.active{
            &.circles{
              .stepper_title{
                color: color(Secondary, Solid, 600);
                font-weight: bold;
              }
              .stepper_circle{
                box-shadow: 0px 4px 16px rgba(138, 163, 2, 0.4);
                border-color: color(Secondary, Solid, 600);
                background: transparent;
                &::after{
                  width: 100%;
                  height: 100%;
                }
              }
            }
            &.line{
              .stepper_line{
                &::after{
                  width: 100%;
                }  
              }
            }
          }
          &.current{
            &.circles{
              .stepper_circle{
                border-color: color(Secondary, Solid, 600);
              }
            }
          }
          &.filled{
            .stepper_circle{
              .checkmark{
                opacity: 1;
              }
            }
          }
        }
      }
    }
    .right_side{
      width: px-to-em(160, 16);
      // background: green;
      .search_game_container{
        width: 100%;
        .search_game{
          @media (orientation: landscape) and (max-width: 767px) {
            margin-left: 30px;
          }
          @extend %large-primary-main;
          width: 100%;
          height: 56px;
          font-size: 17px!important;
        }
      }
    }
    @media (max-width: 767px) {
      @media (orientation: portrait) {
        flex-wrap: wrap;
        justify-content: center;
        padding: 0;
        .left_side{
          display: none;
        }
        .center{
          width: 100%;
          .setpper_container{
            .stepper_part{
              height: 58px;
              &.circles{
                .stepper_circle{
                  width: 24px;
                  height: 24px;
                  .checkmark{
                    svg{
                      width: 10px;
                    }
                  }
                }
              }
            }
          }
        }
        .right_side{
          width: 90%;
          max-width: 360px;
        }
      }
      @media (orientation: landscape) {
        .center{
          .setpper_container{
            .stepper_part{
              height: 58px;
              &.circles{
                .stepper_circle{
                  width: 24px;
                  height: 24px;
                  .checkmark{
                    svg{
                      width: 10px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    @media (max-width: 739px){
      padding: 0 16px;
      justify-content: center;
      @media (orientation: landscape){
        .left_side{
          display: none;
        }
        .center{
          width: auto;
        }
      }
    }
    @media (max-width: 359px) {
      @media (orientation: portrait) {
        .center{
          width: 100%;
          .setpper_container{
            .stepper_part{
              &.line{
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 1023px) {
    width: 100%;
    height: 100%;
    transform: unset;
    border-radius: 0;
    top: 0;
    left: 0;
    border: none;
    --bottom-height: 110px;
    @media (orientation: landscape) {
      --image-size: 64px;
    }
    @media (orientation: landscape) {
      --bottom-height: 92px
    }
  }
  @media (max-width: 767px) {
    --image-size: 48px;
    --bottom-height: 92px;
    @media (orientation: portrait) {
      &.extended{
        --bottom-height: 160px;
      }
    }
  }
}


.searching_progress_players {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6px;
  .icon_container{
    margin-right: 6px;
    img {
      width: 28px;
      display: block;
    }
  }

  .numbers_container{
    padding: 4px;
    border-radius: 24px;
    color: color(White, Solid);
    background-color: color(Green, Transparent, 300);
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 34px;
    span{
      font-size: 14px;
      &.player_number{
        font-weight: bold;
      }
    }
    &.disabled{
      background-color: color(Black, Transparent, 100);
    }
  }
}