@import '../../functions';

.dashboard{
  width: 100%;
  font-size: 16px;
  height: 100%;
  // padding: px-to-em(40, 16) 0;
  box-sizing: border-box;
  .game_types_holder{
    width: 100%;
    .game_types_ul{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      padding: 0;
      .game_types_li{
        display: block;
        width: getPercent(348, 1280);
        max-width: 550px;
        position: relative;
        margin-right: 2em;
        .not_available{
          opacity: 0.4;
          cursor: no-drop !important;
          .game_type{
            .content{
              cursor: no-drop !important;
            }
          }
        }
        .game_type_container {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          transform: skewX(-5deg);
          box-sizing: border-box;
          border-radius: px-to-em(24, 16);
          background: color(Primary, Solid, 600);
          transition: all 500ms;
          box-shadow: 0px 8px 16px rgba(30, 55, 99, 0.4);
          overflow: hidden;

          .game_type{
            width: 100%;
            height: 98%;
            border: 4px solid #234785;
            box-sizing: border-box;
            border-radius: px-to-em(24, 16);
            position: relative;
            overflow: hidden;
            .content {
              transform: skewX(5deg);
              display: flex;
              justify-content: center;
              align-items: flex-start;
              flex-wrap: wrap;
              width: 100%;
              height: 100%;
              cursor: pointer;
              box-sizing: border-box;
              .not_available {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                // background: blue;
                transform: scaleX(1.5);
                // opacity: 0.2;
              }
              .game_type_top_side{
                width: 100%;
                height: 50%;
                display: flex;
                justify-content: center;
                align-items: flex-end;
                .top_content{
                  width: getPercent(80, 274);
                  position: relative;
                  .bubble{
                    position: absolute;
                    z-index: 1;
                    top: 15%;
                    left: -10%;
                    transition: all 500ms;
                    transition-delay: 150ms;
                    opacity: 0;
                    @media (max-width: 1023px) {
                      opacity: 1;
                      left: -15%;
                      top: -5%;
                    }
                    @media (max-width: 992px) {
                      opacity: 1;
                      left: -40%;
                      top: -15%;
                    }
                    @media (max-width: 768px) {
                      opacity: 1;
                      left: -30%;
                      top: -7%;
                    }
                    @media (max-width: 359px) {
                      opacity: 1;
                      left: -30%;
                      top: -7%;
                      width: 35px;
                      img {
                        width: 100%;
                      }
                    }
                  }
                  .image_container{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    border-radius: 50%;
                    border: 6px solid;
                    box-sizing: border-box;
                    overflow: hidden;
                    img {
                      width: 100%;
                      height: 100%;
                      transition: all 500ms;
                      transition-delay: 150ms;
                      &:first-child{
                        position: absolute;
                        top: 0;
                        left: 0;
                      }
                      &:last-child{
                        position: absolute;
                        top: 100%;
                        left: 0;
                      }
                      @media (max-width: 1023px) {
                        &:first-child{
                          top: -100%;
                        }
                        &:last-child{
                          top: 0%;
                        }
                      }
                    }
                    &.classic{
                      background: color(Blue, Solid, 400);
                      border-color: color(Primary, Solid, 500);
                    }
                    &.hero{
                      background: color(Red, Solid, 400);
                      border-color: color(Red, Solid, 500);
                    }
                    &.team_talk{
                      background: color(Yellow, Solid, 400);
                      border-color: color(Yellow, Solid, 500);
                    }
                    &.private{
                      background: color(Green, Solid, 400);
                      border-color: color(Green, Solid, 500);
                    }
                  }
                  &::after{
                    content: '';
                    display: block;
                    padding-bottom: 100%;
                  }
                }
                @media (max-width: 1024px) {
                  align-items: center;
                  .top_content{
                    width: 35%;
                  }
                }
                @media (max-width: 768px) {
                  .top_content{
                    width: 40%;
                  }
                }
              }
              .game_type_bottom_side{
                width: 100%;
                height: 50%;
                display: flex;
                flex-direction: column; 
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
                position: relative;
                .game_type_label{
                  font-size: px-to-em(24, 16);
                  margin-top: px-to-em(16, 24);
                  width: 100%;
                  font-weight: 700;
                  line-height: px-to-em(29, 24);
                  color: color(White, Solid, 500);
                  text-shadow: -1px 3px 1px rgba(32, 59, 105, 0.4);
                  text-align: center;
                  // text-transform: capitalize;
                  @media (max-width: 1024px) {
                    margin-top: 0;
                  }
                }
                .comming_soon{
                  font-size: 1em; //16px
                  font-weight: bold;
                  line-height: px-to-em(19, 16);
                  color: color(Red, Solid, 25);
                  background: color(Negative, Solid, 500);
                  padding: px-to-em(10, 16) px-to-em(22, 16);
                  transform: skewX(-5deg);
                  border-radius: px-to-em(8, 16);
                  margin-top: px-to-em(20, 16);
                  box-sizing: border-box;
                  border: 2px solid color(Negative, Solid, 400);
                  text-transform: uppercase;
                  z-index: 1;
                  span{
                    transform: skewX(5deg);
                  }
                  @media (max-width: 1024px) {
                    margin-top: 0.5em;
                  }
                }
              }
            }
            .backgrounds{
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
              transition: all 500ms;
              &.background_1{
                background: gradient(Primary, 500);
                opacity: 1;
              }
              &.background_2{
                background: gradient(Primary, 600);
                opacity: 0;
              }
            }
            &:hover{
              border-color: color(Primary, Solid, 600);
              .background_1{
                opacity: 0;
              }
              .background_2{
                opacity: 1;
              }
            }
          }
          &:hover{
            // box-shadow: 0px 8px 16px rgba(30, 55, 99, 0.4);
            // transform: translateY(-10px) skewX(-5deg);
            .bubble{
              opacity: 1!important;
              top: 0!important;
            }
            .image_container{
              img{
                &:first-child{
                  top: -100% !important;
                }
                &:last-child{
                  top: 0!important;
                }
              }  
            }
          }
        }
        &::after{
          content: "";
          display: block;
          padding-bottom: getPercent(264, 274);
          
        }
        &:last-child{
          margin-right: 0;
        }
        
        // @media (max-width: 1464px) {
        //   // width: getPercent(324, 1464);
        // }
        // @media (max-width: 1464px) {
        //   // width: 23%;
        // }
        // @media (max-width: 992px) {
        //   width: getPercent(152, 983);
        //   min-width: 152px;
        //   // margin-right: 4px;
        // }
        @media (max-width: 680px) and (orientation: portrait) {
          margin-right: 0;
          width: 50%;
          padding: 0 5px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          margin-top: 12px;
          &::after{
            display: none;
          }
          .game_type_container{
            width: 152px;
            height: 146px;
            position: relative;
          }
          &:nth-child(odd) {
            // margin-right: 20px;
            
            justify-content: flex-end;
          }
          &:nth-child(even) {
            // margin-right: 20px;
            justify-content: flex-start;
          }
          &:nth-child(-n + 2) {
            margin-top: 0;
          }
        }
        @media (max-width: 664px) and (orientation: landscape) {
          min-width: 126px;
          margin-right: 4px;
        }
        @media (max-width: 359px) and (orientation: portrait) {
          .game_type_container{
            width: 140px;
            height: 130px;
            position: relative;
          }
          margin-top: 8px;
        }
        // @media (max-width: 767px) {
        //   font-size: 14px;
        //   width: 35%;
        //   margin-top: px-to-em(12, 14);
        // }
        // @media (max-width: 600px) {
        //   width: 40%;
        //   margin-top: px-to-em(12, 14);
        // }
        // @media (max-width: 450px) {
        //   font-size: 10px;
        // }
      }
      
      // @media (max-width: 680px) and (orientation: portrait) {
      //   width: 330px;
      //   margin: 0 auto;
      // }
    }
  }
  .dashboard_action_buttons{
    width: 100%;
    margin-top: px-to-em(24, 16);
    position: relative;
    .dashboard_action_buttons_ul{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      padding: 0;
      .dashboard_action_buttons_li{
        display: block;
        width: getPercent(220, 1280);
        min-width: 200px;
        max-width: 350px;
        position: relative;
        margin-right: 12px;
        height: 120px;
        // margin-right: px-to-em(8, 16);
        .action_button{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          transform: skewX(-5deg);
          border-radius: px-to-em(35, 16);
          box-sizing: border-box;
          cursor: pointer;
          box-shadow: 0px 4px 16px rgba(138, 163, 2, 0.4);
          background: gradient(Secondary, 500);
          box-shadow: 0px 4px 16px rgba(138, 163, 2, 0.4);
          transition: all 500ms;
          .action_button_content{
            overflow: hidden;
            transition: all 500ms;
            border: 4px solid #8BA501;
            border-radius: px-to-em(35, 16);
            // transform: skewX(-5deg);
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            position: relative;
            .background{
              position: absolute;
              top: -5%;
              left: -5%;
              width: 110%;
              height: 110%;
              transition: all 500ms;
              &.background_1{
                // background: gradient(Secondary, 500);
                opacity: 1;
              }
              &.background_2{
                background: gradient(Secondary, 600);
                opacity: 0;
              }
            }
            .action_button_container{
              transform: skewX(5deg);
              width: 100%;
              height: 100%;
              .button_image_container{
                display: flex;
                justify-content: center;
                align-items: flex-end;
                width: 100%;
                height: 45%;
                img {
                  width: 30px;
                }
              }
              .button_text_container{
                width: 100%;
                height: 55%;
                text-align: center;
                .button_title{
                  font-size: px-to-em(18, 16);
                  line-height: px-to-em(22, 18);
                  font-weight: bold;
                  color: color(White, Solid, 500);
                  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
                  font-weight: 600;
                  margin-top: px-to-em(8, 18);
                  // text-transform: capitalize;
                }                
                .button_sub_text{
                  display: flex;
                  width: 100%;
                  text-align: center;
                  font-size: px-to-em(12, 16);
                  line-height: px-to-em(14, 12);
                  color: color(White, Solid, 500);
                  margin-top: px-to-em(8, 12);
                  justify-content: center;
                  align-items: center;
                  // text-transform: capitalize;
                  .number {
                    display: inline-block;
                    margin-right: 5px;
                    padding: 2px 8px;
                    min-width: 24px;
                    min-height: 16px;
                    border-radius: 24px;
                    background: color(White, Transparent, 200);
                    font-size: 10px;
                    line-height: 0.8rem;
                    font-weight: bold;
                  }
                }
                .comming_soon{
                  font-size: px-to-em(10, 16); //16px
                  font-weight: bold;
                  line-height: px-to-em(19, 10);
                  color: color(White, Solid, 500);
                  background: color(Black, Transparent, 100);
                  padding: px-to-em(5, 10) px-to-em(10, 10);
                  transform: skewX(-5deg);
                  border-radius: px-to-em(8, 10);
                  margin-top: px-to-em(4, 10);
                  box-sizing: border-box;
                  text-transform: uppercase;
                  z-index: 1;
                  display: inline-block;
                  // width: auto;
                  // max-width: 60%;
                  // margin: 0 auto;
                  span{
                    transform: skewX(5deg);
                  }
                }
              }
              // @media (max-width: 768px) {
              //   .button_image_container{
              //     align-items: center;
              //   }
              //   .button_text_container{
              //     .button_title{
              //       margin-top: 0;
              //     }
              //   }
              // }
            }
          }
          &:last-child{
            margin-right: 0;
          }
          &:hover{
            // background: gradient(Secondary, 600);
            box-shadow: 0px 4px 24px rgba(139, 165, 1, 0.4);
            .background_1{
              opacity: 0!important;
            }
            .background_2{
              opacity: 1!important;
            }
            .action_button_content{
              border-color: color(Secondary, Solid, 600);
            }
          }
        }
        .indicator{
          font-size: 12px;
          // line-height: px-to-em(12, 10);
          width: px-to-em(20, 12);
          height: px-to-em(20, 12);
          display: flex;
          justify-content: center;
          align-items: center;
          color: color(White, Solid);
          // border-radius: px-to-em(24, 10);
          border-radius: 50%;
          background: gradient(Negative, 500);
          margin-left: px-to-em(4, 12);
          &.outer{
            display: none;
            // position: absolute;
            // top: 0;
            // right: 8px;
            // transform: translateY(-30%) skew(5deg);
          }
          @media (max-width: 992px){
            &.inner{
              display: none;
            }
            &.outer{
              display: flex;
            }
          }
        }
        .extra_info{
          display: none;
          width: auto;
          height: 20px;
          background: color(Secondary, Solid, 200);
          padding: 2px 4px;
          border-radius: 40px;
          border: 1px solid color(Secondary, Transparent, 200);
          transform: skew(5deg) translateY(-50%);
          position: absolute;
          top: 0;
          right: 16px;
          justify-content: center;
          align-items: center;
          .button_sub_text{
            font-size: 10px;
            color: color(Black, Solid, 400);
            .number{
              padding: 2px 5px;
              border-radius: 24px;
              background: color(Black, Transparent, 50);
              display: inline-block;
              margin-right: 4px;
              font-weight: bold;
            }
          }
          &.my_turns_count{
            background: color(Negative, Solid, 500);
            border: none;
            display: flex;
            .button_sub_text{
              color: color(White, Solid);
              .number{
                background: color(Black, Transparent, 100);
                margin-right: 0;
                margin-left: 4px;
              }
            }
          }
          @media (max-width: 992px) {
            display: flex;            
          }
        }
        &.disabled{
          opacity: 0.4;
          cursor: no-drop;
          .action_button{
            cursor: inherit;
          }
        }
        @media (max-width: 992px) {
          height: 100px;
        }

        @media (max-width: 992px) {
          width: getPercent(220, 983);
          min-width: 128px;
          font-size: 8px;
          .action_button{
            .action_button_content{
              border-radius: 16px;
              .button_image_container{
                height: 50%!important;
                display: flex!important;
                justify-content: center;
                align-items: flex-end!important;
                img {
                  width: 17%!important;
                }
              }
              .button_text_container{
                height: 50%!important;
                display: flex;
                justify-content: center;
                align-items: center;
                .button_title{
                  margin-top: unset!important;
                  font-size: 1.8em!important;
                }
                .button_sub_text{
                  display: none!important;
                }
                .comming_soon{
                  display: none!important;
                }
                @media (max-width: 992px) {
                  .button_title{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }
                }
              }
            }
          }
        }
        @media (max-width: 680px) and (orientation: portrait) {
          .action_button{
            height: 56px;
            position: relative;
          }
        }
        @media (max-width: 664px) and (orientation: landscape) {
          min-width: 108px;
        }
        @media (max-width: 768px) {
          height: 90px;
        }
      }
      @media (max-width: 680px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 48px;
        .dashboard_action_buttons_li{
          margin: 0;
          padding: 0;
          justify-content: center;
          margin-bottom: 16px;
          width: 248px;
          height: 56px;
          &:last-child{
            margin-bottom: 0;
          }
          .action_button{
            width: 100%;
            height: 56px;
            .action_button_content{
              .action_button_container{
                .button_image_container{
                  img{
                    width: 20px;
                    height: 20px;
                  }
                }
              }
            }
          }

        }
      }
    }
    @media (max-width: 680px) and (orientation: portrait) {
      // margin-top: 32px;
    }
    @media (max-width: 359px) and (orientation: portrait) {
      // margin-top: 12px;
    }
    @media (max-width: 650px) {
      // padding: 0 px-to-em(30, 10);
      // width: 25%;
    }
    @media (max-width: 550px) {
      // padding: 0 px-to-em(10, 10);
      // width: 25%;
    }
  }
  .quick_play_button_container{
    margin-top: px-to-em(70, 16);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    button{
      color: white;
      text-transform: uppercase;
      font-size: px-to-em(24, 16);
      line-height: px-to-em(29, 24);
      padding: px-to-em(18, 24) px-to-em(55, 24);
      transform: skewX(-5deg);
      cursor: pointer;
      background: linear-gradient(266.1deg, #FFC328 0%, #EB8F3A 100%);
      border: 4px solid #E4923C;
      box-sizing: border-box;
      box-shadow: 0px 4px 16px rgba(204, 173, 32, 0.4);
      border-radius: 12px;
      transition: all 500ms;
      &:hover {
        background: linear-gradient(266.1deg, #ECB017 0%, #DC7D26 100%);
        border-color: #D37F27;
        box-shadow: 0px 4px 24px rgba(204, 173, 32, 0.4);
      }
      &:disabled{
        background: linear-gradient(266.1deg, #FFC328 0%, #EB8F3A 100%);
        box-shadow: 0px 4px 16px rgba(204, 173, 32, 0.4);
        opacity: 0.5;
        cursor: no-drop;
      }
      &.clicked {
        background: linear-gradient(266.1deg, #FFD15A 0%, #EEA058 100%);
        border-color: #E4923C;
        box-shadow: 0px 4px 8px rgba(204, 173, 32, 0.24);
      }
      span{
        transform: skewX(5deg);
        display: inline-block;
      }
    }
    @media (max-width: 992px) {
      button {
        width: 158px;
        height: 48px;
        padding: 0;
        margin-bottom: 5vh;
      }
      @media (orientation: landscape) {
        margin-top: 12px;
        margin-bottom: 12px;
      }
      @media (orientation: portrait) {
        margin-top: 32px;
        margin-bottom: 32px;
      }
    }
    @media (max-width: 739px) {
      button {
        width: 130px;
        height: 40px;
        font-size: 14px;
      }
    }
    @media (max-width: 359px) {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  @media (max-width: 1366px) {
    font-size: 12px;
  }
  @media (max-width: 768px) {
    font-size: 10px;
  }
  &.tablet{
    @media (orientation: portrait) {
      .game_types_holder{
        .game_types_ul{
          .game_types_li{
            margin-right: 0;
            width: 50%;
            padding: 0 5px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            margin-top: 12px;
            &::after{
              display: none;
            }
            .game_type_container{
              width: 274px;
              height: 264px;
              position: relative;
              border-radius: 24px;
              .game_type{
                border-radius: 24px;
                .content{
                  .game_type_bottom_side{
                    .game_type_label{
                      font-size: 24px;
                    }
                    .comming_soon{
                      font-size: 1.3em;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .dashboard_action_buttons{
        .dashboard_action_buttons_ul{
          .dashboard_action_buttons_li{
            margin-right: 0;
            width: 50%;
            padding: 0 5px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            margin-top: 8px;
            &::after{
              display: none;
            }
            .action_button{
              width: 220px;
              height: 120px;
              position: relative;
              border-radius: 24px;
              .action_button_content{
                border-radius: 24px;
                .button_image_container{
                  height: 45%!important;
                }
                .button_text_container{
                  height: 55%!important;
                  display: flex;
                  flex-wrap: wrap!important;
                  .button_title{
                    font-size: 18px;
                  }
                  .button_sub_text{
                    display: flex!important;
                    font-size: 12px;
                    margin-top: 0;
                    .indicator{
                      display: flex!important;
                    }
                  }
                }
              }
              .indicator{
                display: none;
              }
            }
            &:nth-child(odd) {
              // margin-right: 20px;
              justify-content: flex-end;
            }
            &:nth-child(even) {
              // margin-right: 20px;
              justify-content: flex-start;
            }
            &:nth-child(-n + 2) {
              margin-top: 0;
            }
          }
        }
      }
      .quick_play_button_container{
        button{
          font-size: 24px!important;
          width: 245px!important;
          height: 64px!important;
          border-radius: 12px;
        }
      }
    }
  }
}

.cooldown{
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 12px;
  overflow: hidden;
}

.onbording_setpper_container{
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 999999;
  @media (max-width: 768px) {
    @media (orientation: portrait) {
      right: 0;
      width: 100%;
    }
  }
}