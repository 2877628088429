@import '../../styles/scss/functions';
@import '../../styles/scss/extends';

.modal_container{
  background: #F6F8FA;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  width: px-to-em(800, 16);
  height: px-to-em(614, 16);
  border-radius: 2em;
  border: 4px solid color(Black, Transparent, 50);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  z-index: 9999;
  --bottom-height: 104px;
  // --image-size: 115px;
  --mode-button-size: 450px;
  // transition: all 500ms;
  .modal_head{
    transition: all 500ms;
    .modal_details_container{
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, -37%);
    }
    .modal_image_container{
      width: 80px;
      height: 80px;
      border: 6px solid transparent;
      border-radius: 50%;
      margin: 0 auto;
      &.classic{
        background: #E1E9F9;
        border-color: color(Primary, Solid, 500);
        filter: drop-shadow(-1px 4px 16px rgba(35, 71, 133, 0.32));
      }
      &.any{
        border-color: #E4923C;
        filter: drop-shadow(-1px 4px 16px rgba(228, 146, 60, 0.32));
      }
      .modal_image{
        width: 100%;
        height: 100%;
      }
    }
    .modal_title_container{
      width: 100%;
      margin-top: px-to-em(8, 16);
      .modal_title{
        text-align: center;
        font-size: px-to-em(24, 16);
        color: color(Black, Solid, 400);
        font-weight: 700;
      }
    }
    .modal_close_button_container{
      position: absolute;
      right: 24px;
      top: 24px;
    }
    @media (max-width: 1023px) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      .modal_details_container{
        transform: unset;
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        left: 0;
        .modal_title_container{
          width: auto;
          .modal_title{
            display: inline-block;
            width: auto;
            font-size: px-to-em(32, 16);
            padding-left: 1em;
            margin: 0;
          }
        }
        .modal_image_container{
          margin: 0;
        }
      }
      .modal_close_button_container{
        position: relative;
        top: 0;
        left: 0;
        transform: unset;
      }
      @media (orientation: landscape) {
        padding: px-to-em(8, 16) 1em;
        .modal_details_container{
          justify-content: center;
        }
        .modal_image_container{
          width: var(--image-size);
          height: var(--image-size);
        }
      }
      @media (orientation: portrait) {
        padding: 12px getPercent(12, 360);
        .modal_details_container{
          justify-content: flex-start;
        }
        .modal_image_container{
          width: var(--image-size);
          height: var(--image-size);
        }
        .modal_close_button_container{
          button{
            // width: calc(#{var(--image-size)} / 2);
            // height: calc(#{var(--image-size)} / 2);
            width: 32px;
            height: 32px;
            min-width: 32px;
            min-height: 32px;
            img {
              // width: 100%;
              // max-width: 22px;
            }
          }
        }
      }
    }
    @media (max-width: 767px) {
      .modal_details_container{
        .modal_title_container{
          .modal_title{
            font-size: 24px;
          }
        }
      }
    }
  }
  .upper_part{
    background: white;
    width: 100%;
    height: calc(100% - var(--bottom-height));
    border-radius: 2em;
    transition: all 500ms;
    flex-wrap: wrap;
    .dynamic_part{
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding-top: 70px;
      transition: all 500ms;
      overflow-y: auto;
      overflow-x: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      .info_container{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding:  0 getPercent(120, 800);
        .illustration_container{
          svg {}
        }
        .text_container{
          width: 100%;
          margin-top: 40px;
          .info_title{
            font-size: 24px;
            width: 100%;
            text-align: center;
            color: color(Black, Solid, 400);
          }
          .info_text{
            font-size: 18px;
            width: 100%;
            text-align: center;
            color: color(Black, Solid, 300);
            margin-top: 16px;
          }
        }
      }
      // .dynamic_body{
      //   height: calc(100% - px-to-em(35.5, 16));
      //   width: 100%;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      // }
    }
    @media (max-width: 1023px) {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      border-radius: 0;
      .dynamic_part{
        padding-top: 0;
      }
      @media (orientation: portrait) {
        .dynamic_part{
          height: calc(100% - #{var(--image-size)} - 24px);
        }
      }
      @media (orientation: landscape) {
        .dynamic_part{
          height: calc(100% - #{var(--image-size)} - 16px);
        }
      }
    }
    @media (max-width: 992px) {
      @media (orientation: portrait) {
        .dynamic_part{
        }
      }
      @media (orientation: landscape) {
        .dynamic_part{
          height: calc(100% - #{var(--image-size)} - 16px);
        }
      }
    }
    @media (max-width: 768px) {
      @media (orientation: portrait) {
        .dynamic_part{
          .info_container{
            padding:  0 7%;
          }
        }
      }
    }
    @media (max-width: 500px) {
      @media (orientation: portrait) {
        .dynamic_part{
          .info_container{
            .illustration_container{
              svg {
                transform: scale(0.7);
              }
            }
          }
        }
      }
    }
  }
  .bottom_part{
    height: var(--bottom-height);
    width: 100%;
    padding: 0 px-to-em(32, 16);
    display: flex;
    box-sizing: border-box;
    justify-content: flex-start;
    align-items: center;
    transition: all 500ms;
    .center{
      width: 100%;
      display: flex;
      justify-content: center;
      .action_button{
        @extend %large-primary-main;
      }
    }
    @media (max-width: 767px) {
      @media (orientation: portrait) {
        flex-wrap: wrap;
        justify-content: center;
        padding: 0;
        .center{
          width: 100%;
        }
      }
      @media (orientation: landscape) {
        .center{
        }
      }
    }
    @media (max-width: 739px){
      padding: 0 16px;
      justify-content: center;
      @media (orientation: landscape){
        .center{
          width: auto;
        }
      }
    }
    @media (max-width: 359px) {
      @media (orientation: portrait) {
        .center{
          width: 100%;
        }
      }
    }
  }
  @media (max-width: 1023px) {
    width: 100%;
    height: 100%;
    // transform: translate(0,0);
    border-radius: 0;
    // bottom: 0;
    // right: 0;
    border: none;
    --bottom-height: 110px;
    @media (orientation: landscape) {
      --image-size: 64px;
    }
    @media (orientation: landscape) {
      --bottom-height: 92px
    }
  }
  @media (max-width: 767px) {
    --image-size: 48px;
    --bottom-height: 92px;
    @media (orientation: portrait) {
      .bottom_part{
        &.extended{
          --bottom-height: 160px;
        }
      }
    }
  }
  @media (max-width: 600px) {
    .bottom_part{
      .center {
      }
    }
  }
}

.zIndexHigh {
  z-index: 9999;
}

@keyframes unsetZetIndex {
  from {
    z-index: 2;
  } to {
    z-index: unset;
  }
}